import { api } from '../utils/api';

export const SBER_PASS_KEY = 'sber_one_time_pass';

export const getSberToken = async (oneTimePass: string) => {
  try {
    const response = await api.get<{ token: string }>(
      `/api/v1/users/sberID-token?otp=${oneTimePass}`,
    );
    const { token } = response || {};

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(SBER_PASS_KEY);
    window.location.search = searchParams.toString();

    return { token };
  } catch (error) {
    return { signInError: error };
  }
};
