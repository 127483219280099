import { api } from '../utils/api';

export type AccompanyingGuest = {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: null | string;
  attachments: [];
  relation_degree: {
    id: number;
    name: string;
  };
};

export type UserInfo = {
  id: number;
  first_name: string;
  last_name: string;
  middle_name?: string;
  is_hotel_guest: boolean;
  phone: string;
  email: string;
  username: string;
  user_guests: AccompanyingGuest[];
  hotel_employee_access: boolean;
  is_company_employee: boolean;
};

export async function getUserInfo() {
  const response = await api.get<UserInfo>('/api/v1/users/info');
  return response;
}
