import { api } from '../utils/api';

export type ExtraGuest = {
  guestId: number | null;
  firstName: string;
  lastName: string;
  middleName: string;
  kinship: number;
//   userId: number;
  // files: File[];
};

type CreateGuestBodyRequest = {
  id: null | number;
  email: null;
  first_name: string;
  last_name: string;
  relation_degree_id: number;
  attachments: number[];
};

export const createOrUpdateGuest = async (hotelId: number, bodyRequest: CreateGuestBodyRequest) => {
  const url = `/api/v1/users/guest/${hotelId}/`;
  return api.post<{id: number; warnings: unknown}>(url, bodyRequest);
};
