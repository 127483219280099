import { ReactNode, useLayoutEffect, useState } from 'react';
import './sticky-bottom.css';

export const StickyBottom = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const [position, setPosition] = useState('sticky');

  useLayoutEffect(() => {
    const root = document.getElementById('root');

    if (!root) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const entry = entries.find((e) => e.target === root);
      if (!entry) {
        return;
      }

      const { height } = entry.contentRect;
      const screenHeight = window.innerHeight;

      if (screenHeight > height) {
        position == 'sticky' && setPosition('fixed');
      } else {
        position == 'fixed' && setPosition('sticky');
      }
    });

    observer.observe(root);

    return () => observer.unobserve(root);
  }, [position]);

  return (
    <section
      className={
        `${position} custom-shadow bottom-0 w-full bg-neutral-0 p-5 text-center ` + className
      }
    >
      {children}
    </section>
  );
};
