import { ReactNode, createContext, useEffect, useState } from 'react';
import { RootStore } from './rootStore';

export const RootStoreContext = createContext<RootStore | undefined>(undefined);

export function RootStoreProvider({ children }: { children: ReactNode }) {
  const [root] = useState(() => new RootStore());

  useEffect(() => {
    return root.dispose();
  }, [root])

  return <RootStoreContext.Provider value={root}>{children}</RootStoreContext.Provider>;
}

RootStoreContext.displayName = 'RootStoreContext'
