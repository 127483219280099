import { makeAutoObservable } from 'mobx';
import { RootStore } from './rootStore';

export class ErrorStore {
  root: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.root = rootStore;
  }

  error: null | Error = null;
  setError(err: null | Error) {
    this.error = err;
  }

  clearError() {
    this.error = null;
  }
}
