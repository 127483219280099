import { NavLink, Outlet, To } from 'react-router-dom';
import { ReactNode } from 'react';
import { useRootStore } from '../../stores/useRootStore';
import { BasicAvatar } from '../../components/Header';
import { Heading3 } from '../../components/shared/Heading';
import SberIcon from '../../assets/sber-logo.svg?react';

const TabLink = ({ children, to }: { children: ReactNode; to: To }) => (
  <NavLink
    to={to}
    end
    className={({ isActive }) =>
      `flex flex-1 justify-center border-b xl:block xl:flex-none border-neutral-${isActive ? '700' : '70'} text-neutral-${isActive ? '700' : '70'}`
    }
  >
    <div className="w-[120px] py-2 text-center text-xl ">{children}</div>
  </NavLink>
);

export const Account = () => {
  const { authStore } = useRootStore() || {};
  const user = authStore.user;

  return (
    <div className="container mx-auto  max-w-[1168px] xl:mt-10">
      <div className="xl:hidden flex flex-col items-center px-6 py-5 border-b border-b-neutral-30">
        {authStore.isCompanyEmployee ? (
          <SberIcon className={'mr-3'} />
        ) : (
          <BasicAvatar user={user!} />
        )}
        <Heading3 className="!mb-0 mt-3 text-center">
          {user ? `${user.last_name} ${user.first_name}` : ''}
        </Heading3>
      </div>

      {/* <Heading1 className="text-center">Личный кабинет</Heading1> */}
      <h1 className="mt-8 text-center font-title text-2xl font-light uppercase text-neutral-900 xl:text-[40px] xl:leading-[56px]">
        Личный кабинет
      </h1>

      <section className="mb-6 mt-6 flex justify-center px-5 xl:mt-10 xl:px-0">
        <TabLink to="/account">Брони</TabLink>
        <TabLink to="/account/info">Профиль</TabLink>
      </section>

      <section>
        <Outlet />
      </section>
    </div>
  );
};
