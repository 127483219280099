import { api } from '../utils/api';
import { RateSummary } from '../views/Account/BookingList';

export type BookingInvoice = {
  payment_type: string;
  payment_category: string;
  payment_selected_method: string;
  amount: number;
  payment_link: string;
  paid: true;
  paid_time: string;
  cancel_reservation_date: string;
  link_expiration_date: string;
};

export type AccompanyingGuest = {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: null | string;
  attachments: [];
  relation_degree: {
    id: number;
    name: string;
  };
};

export type BookingInfo = {
  id: number;
  crs_number: string;
  read_only: boolean;
  childs: [
    {
      cc_name: string;
      price: number;
      quantity: number;
    },
    {
      cc_name: string;
      price: number;
      quantity: number;
    },
  ];
  room_type: {
    id: number;
    name: string;
    code: string;
    img: string;
    short_description: string;
    long_description: string;
    square: number;
  };
  link_key: string;
  created: string;
  adults: number;
  arrival: string;
  departure: string;
  is_sber_employee: true;
  guest_first_name: string;
  guest_last_name: string;
  guest_middle_name: string;
  guest_email: string;
  guest_phone: string;
  status: string;
  payment_status: string;
  payment_expire: null;
  canceled_type: null;
  cancel_comment: null;
  external_id: null;
  rooms_count: number;
  rate: RateSummary;
  confirmed: boolean;
  price: string;
  original_price: string;
  reservation_payments: BookingInvoice[];
  accompanying_guests: AccompanyingGuest[];
};

export async function getBookingInfo(bookingId: string) {
  const url = `/api/v1/account-reservation/detail/${bookingId}/`;

  const response = await api.get<BookingInfo>(url);
  return response;
}
