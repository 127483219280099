import { Button } from '../../components/shared/Button/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { CenteredLayout } from '../../components/shared/CenteredLayout';
import { Field } from '../../components/forms/types';
import { InputField } from '../../components/shared/InputField/InputField';
import { useRootStore } from '../../stores/useRootStore';
import { Heading1 } from '../../components/shared/Heading';

type AuthFormInput = {
  login: string;
  password: string;
};

const fields: Field[] = [
  {
    label: 'Логин',
    name: 'login',
    type: 'text',
    autocomplete: 'username',
    placeholder: 'Обязательное поле*',
    registerOptions: {
      required: {
        value: true,
        message: 'Это поле не должно быть пустым',
      },
    },
  },
  {
    label: 'Пароль',
    name: 'password',
    type: 'password',
    autocomplete: 'current-password',
    placeholder: 'Обязательное поле*',
    registerOptions: {
      required: {
        value: true,
        message: 'Это поле не должно быть пустым',
      },
      minLength: {
        value: 8,
        message: 'Длина пароля должна быть не менее 8 символов',
      },
    },
  },
];


export const AuthPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthFormInput>();

  const navigate = useNavigate();

  const { authStore } = useRootStore()

  const onSubmit: SubmitHandler<AuthFormInput> = async (data) => {
    const success = await authStore.login(data);
    success && navigate('/');
  };

  return (
    <CenteredLayout>
      <div className="mb-10 text-center">
        <Heading1>Авторизация</Heading1>
        <p className="text-lg font-light">Введите ваш логин и пароль</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {fields.map((f) => (
          <InputField
            key={f.name}
            type={f.type}
            label={f.label}
            placeholder={f.placeholder}
            autoComplete={f.autocomplete}
            {...register(f.name as keyof AuthFormInput, f.registerOptions)}
            error={errors[f.name as keyof AuthFormInput]?.message}
          />
        ))}

        <Button className="w-full">Войти</Button>
        <Link to={'/'} className="block">
          <Button style="secondary" variant="link" className="w-full">
            Войти без авторизации
          </Button>
        </Link>
      </form>
    </CenteredLayout>
  );
};

// <a href={sberLink}>
//     <Button className="w-full !bg-hz-1">Войти по Сбер ID</Button>
// </a>
