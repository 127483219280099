import { api } from '../utils/api';

type Params = {
  arrival: string;
  departure: string;
  withPromocode?: boolean;
  rateFlow?: string; // TODO
};

export type Availability = {
  date: string;
  price: number;
  availability: {
    color: string; // hex
    font_color: string; // hex
    legend: string;
  };
};

export async function getAvailability(hotelId: number, params: Params) {
  const { arrival, departure, withPromocode, rateFlow } = params;
  const url = `/api/v1/color-availability/${hotelId}/?arrival=${arrival}&departure=${departure}${
    withPromocode ? `&is_promocode=1` : ``
  }${rateFlow ? `&rate_flow=${rateFlow}` : ``}`;

  const response = await api.get<Availability[]>(url);
  return response;
}
