import { ReactNode } from "react";
// import { useLocation } from "react-router-dom";
// import { CookieUtils } from "../utils/cookies";

// const useAuth = () => {
//     const authDisabled = import.meta.env.VITE_AUTH_DISABLED;
//     // getUserInfo();
//     const token = CookieUtils.getToken();
//     return authDisabled || token;
// }

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    // const user = useAuth();
    // const location = useLocation();

    return children;

    // if (!user) {
    //     return <Navigate to="/welcome" state={{ from: location }} replace />
    // } else {
    //     return children;
    // }
}

