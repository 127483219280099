import { ParamsStore } from './paramsStore';
import { HotelConfigStore } from './hotelConfigstore';
import { AuthStore } from './authStore';
import { ColorAvailabilityStore } from './availabilityStore';
import { ErrorStore } from './errorStore';
import { BookingsStore } from './bookingsStore';
import { ConfirmStore } from './confirmStore';

export class RootStore {
  paramsStore: ParamsStore;
  configStore: HotelConfigStore;
  authStore: AuthStore;
  colorAvailabilityStore: ColorAvailabilityStore;
  bookingsStore: BookingsStore;
  errorStore: ErrorStore;
  confirmStore: ConfirmStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.paramsStore = new ParamsStore(this);
    this.configStore = new HotelConfigStore(this);
    this.colorAvailabilityStore = new ColorAvailabilityStore(this);
    this.bookingsStore = new BookingsStore(this);
    this.errorStore = new ErrorStore(this);
    this.confirmStore = new ConfirmStore(this);

    this.authStore.init();
    this.paramsStore.init();
    this.configStore.init();
    this.colorAvailabilityStore.init();
  }

  dispose() {
    this.colorAvailabilityStore.dispose();
    this.paramsStore.dispose();
  }
}

export const rootStore = new RootStore();
