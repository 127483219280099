import { shift } from '@floating-ui/react';
import { Dropdown } from '../shared/Dropdown/Dropdown';
import { useState } from 'react';
import { Details } from '../shared/Details/Details';
import { Calendar } from '../Calendar/Calendar';
import { Heading3 } from '../shared/Heading';
import { RateModes, rateModes } from '../RateModes/RateModes';
import { Button } from '../shared/Button/Button';
import EqualizerIcon from '../../assets/equalizer-icon.svg?react';
import ChevronIcon from '../../assets/chevron-up-icon.svg?react';
import { Promocode } from '../Promocode/Promocode';
import { Guests } from '../Guests';
import { useRootStore } from '../../stores/useRootStore';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import noun from 'plural-ru';

type FilterValue = {
  label: string;
  value: string;
};

const Filter = ({
  data,
  isOpen,
}: {
  data: FilterValue | [FilterValue, FilterValue];
  isOpen?: boolean;
}) => {
  return (
    <div className="flex cursor-pointer border-neutral-30 pb-4 pt-3 hover:bg-neutral-30 xl:border-l xl:px-8">
      {Array.isArray(data) ? (
        data.map(({ label, value }, index) => (
          <LabeledValue
            key={label}
            value={value}
            label={label}
            hideArrow={index != data.length - 1}
            isOpen={isOpen}
          />
        ))
      ) : (
        <LabeledValue value={data.value} label={data.label} isOpen={isOpen} />
      )}
    </div>
  );
};

const LabeledValue = ({
  value,
  label,
  isOpen,
  hideArrow = false,
}: {
  value: string;
  label: string;
  hideArrow?: boolean;
  isOpen?: boolean;
}) => (
  <div className="flex-1 px-5">
    <label className="mb-1 text-xs font-light leading-6 text-neutral-70">{label}</label>
    <div className="flex h-6 items-center justify-between">
      <p className="whitespace-nowrap text-[16px] font-light leading-5 text-neutral-900">{value}</p>
      {!hideArrow && <ChevronIcon className={`ml-1 w-6 ${isOpen ? 'rotate-0 ' : 'rotate-180 '}`} />}
    </div>
  </div>
);

const formatDate = (date: Date) => format(date, 'd MMM', { locale: ru });

const useGuestsCounter = (guestCount: number, roomsCount: number) => {
  const roomsNoun = noun(roomsCount, 'комнате', 'комнатах', 'комнатах');
  const guestNoun = noun(guestCount, 'гость', 'гостя', 'гостей');
  return `${guestCount} ${guestNoun} в ${roomsCount} ${roomsNoun}`;
};

export const DesktopFilters = observer(() => {
  const { configStore, authStore, paramsStore } = useRootStore();

  const { range, rateMode, guestRooms } = paramsStore;

  const [adults, childs] = paramsStore.countGuests(paramsStore.guestRooms);
  const guestStat = useGuestsCounter(adults + childs, guestRooms.length);

  return (
    <section className="xl:flex xl:justify-center">
      <Dropdown
        middleware={[shift()]}
        placement="bottom-start"
        anchor={(isOpen) => (
          <Filter
            isOpen={isOpen}
            data={[
              { value: range?.from ? formatDate(range.from) : '-', label: 'Заезд' },
              { value: range?.to ? formatDate(range.to) : '-', label: 'Выезд' },
            ]}
          />
        )}
      >
        <div className="max-w-[1132px] bg-neutral-0 shadow-xl">
          <Calendar />
        </div>
      </Dropdown>

      <Dropdown
        middleware={[shift()]}
        placement="bottom-start"
        anchor={(isOpen) => <Filter isOpen={isOpen} data={{ value: guestStat, label: 'Гости' }} />}
      >
        <div className="max-w-[420px] bg-neutral-0 shadow-xl">
          <Guests />
        </div>
      </Dropdown>

      {authStore.isCompanyEmployee && (
        <Dropdown
          middleware={[shift()]}
          placement="bottom-start"
          anchor={(isOpen) => (
            <Filter
              isOpen={isOpen}
              data={{ value: (rateMode && rateModes[rateMode]) || 'Не выбрано', label: 'Тариф' }}
            />
          )}
        >
          <div className="max-w-[420px] bg-neutral-0 py-6 shadow-xl">
            <Heading3 className="text-center">Тариф</Heading3>
            <RateModes />
          </div>
        </Dropdown>
      )}

      {configStore.hasPromocode && (
        <Dropdown
          middleware={[shift()]}
          placement="bottom-end"
          anchor={(isOpen) => (
            <Filter isOpen={isOpen} data={{ value: 'Введите промокод', label: 'Промокод' }} />
          )}
        >
          <section className="w-[560px] max-w-[560px] bg-neutral-0 p-5 shadow-xl">
            <Promocode />
          </section>
        </Dropdown>
      )}

      <div className="p-5 xl:flex xl:p-0">
        <Button
          variant="filled"
          style="secondary"
          className="hidden w-full items-center justify-center xl:flex"
          onClick={() => paramsStore.loadRooms()}
          disabled={!range?.from || !range?.to || !paramsStore.hasUnappliedParams}
        >
          <span>Подобрать номер</span>
        </Button>
      </div>
    </section>
  );
});

export const MobileFilters = observer(() => {
  const [isOpen, setIsOpen] = useState(false);

  const { configStore, authStore, paramsStore } = useRootStore();

  const { range, rateMode, guestRooms } = paramsStore;

  const toggleParams = () => {
    setIsOpen((state) => !state);
  };

  const [adults, childs] = paramsStore.countGuests(paramsStore.guestRooms);
  const guestStat = useGuestsCounter(adults + childs, guestRooms.length);

  return (
    <>
      {isOpen && (
        <section>
          <Details
            className="border-y border-neutral-30"
            label={(isOpen) => (
              <Filter
                isOpen={isOpen}
                data={[
                  { value: range?.from ? formatDate(range.from) : '-', label: 'Заезд' },
                  { value: range?.to ? formatDate(range.to) : '-', label: 'Выезд' },
                ]}
              />
            )}
          >
            <div className="max-w-[1132px] bg-neutral-0">
              <Calendar />
            </div>
          </Details>

          <Details
            className="border-b border-neutral-30"
            label={(isOpen) => (
              <Filter isOpen={isOpen} data={{ value: guestStat, label: 'Гости' }} />
            )}
          >
            <div className="max-w-[420px] bg-neutral-0">
              <Guests />
            </div>
          </Details>

          {authStore.isCompanyEmployee && (
            <Details
              className="border-b border-neutral-30"
              label={(isOpen) => (
                <Filter
                  isOpen={isOpen}
                  data={{
                    value: (rateMode && rateModes[rateMode]) || 'Не выбрано',
                    label: 'Тариф',
                  }}
                />
              )}
            >
              <div className="max-w-[420px] bg-neutral-0 px-5 py-8">
                <Heading3>Тариф</Heading3>

                <div className="space-y-4">
                  <RateModes />
                </div>
              </div>
            </Details>
          )}

          {configStore.hasPromocode && (
            <Details
              className="border-b border-neutral-30"
              label={(isOpen) => (
                <Filter isOpen={isOpen} data={{ value: 'Введите промокод', label: 'Промокод' }} />
              )}
            >
              <div className="max-w-[420px] bg-neutral-0 px-5 py-8">
                <Promocode />
              </div>
            </Details>
          )}
        </section>
      )}

      <div className="p-5 ">
        {isOpen && (
          <Button
            variant="outlined"
            style="accent"
            className="flex w-full justify-center xl:hidden"
            onClick={() => paramsStore.loadRooms()}
            disabled={!range?.from || !range?.to || !paramsStore.hasUnappliedParams}
          >
            <span>Подобрать номер</span>
          </Button>
        )}
        <Button
          variant="outlined"
          style="secondary"
          onClick={toggleParams}
          className="mt-2.5 flex w-full justify-center xl:hidden"
        >
          <EqualizerIcon className="mr-2" />
          <span>{isOpen ? 'Свернуть' : 'Параметры'}</span>
        </Button>
      </div>
    </>
  );
});
