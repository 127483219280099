import { makeAutoObservable } from 'mobx';
import { RootStore } from './rootStore';
import { BookingItem, getBookingList } from '../apiMethods/getBookingList';
import { BookingInfo, getBookingInfo } from '../apiMethods/getBookingInfo';
import { CancelData, cancelBooking } from '../apiMethods/cancelBooking';
import { RefundInfo, getRefundInfo } from '../apiMethods/getRefundInfo';
import { Kinship, getKinshipTypes } from '../apiMethods/getKinshipTypes';
import { ExtraGuest, createOrUpdateGuest } from '../apiMethods/updateOrCreateGuest';
import { deleteGuest } from '../apiMethods/deleteGuest';
import { updateBookingGuestList } from '../apiMethods/updateBookingGuestList';

export class BookingsStore {
  root: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.root = rootStore;
  }

  loadBookinglist = async () => {
    const hotelId = this.root.configStore.hotelId;
    if (!hotelId) {
      return;
    }
    this.setLoadingList(true);
    const result = await getBookingList(hotelId);

    result && this.setBookingList(result);
    this.setLoadingList(false);
  };

  loadingList = false;
  setLoadingList(state: boolean) {
    this.loadingList = state;
  }

  bookingList: BookingItem[] = [];
  setBookingList(list: BookingItem[]) {
    this.bookingList = list;
  }

  loadingBookingInfo = false;
  setLoadingBookingInfo(state: boolean) {
    this.loadingBookingInfo = state;
  }

  bookingInfo: BookingInfo | null = null;

  setBookingInfo(data: typeof this.bookingInfo) {
    this.bookingInfo = data;
  }

  async loadBookingInfo(bookingId: string) {
    this.setLoadingBookingInfo(true);
    const data = await getBookingInfo(bookingId);
    await new Promise(res => setTimeout(res, 2000))
    data && this.setBookingInfo(data);
    this.setLoadingBookingInfo(false);
  }

  refund: { data: RefundInfo | null; pending: boolean; error: string | null } = {
    data: null,
    pending: false,
    error: null,
  };
  setRefundInfo(refund: Partial<typeof this.refund>) {
    this.refund = { ...this.refund, ...refund };
  }

  async loadRefundInfo(hotelId: number, bookingId: number) {
    this.setRefundInfo({ pending: true });
    const data = await getRefundInfo(hotelId, bookingId);
    data && this.setRefundInfo({ data });
    this.setRefundInfo({ pending: false });
  }

  bookingCancellationPending = false;
  setBookingCancellationPennding(state: boolean) {
    this.bookingCancellationPending = state;
  }

  async cancelBooking({
    hotelId,
    bookingId,
    cancelData,
  }: {
    hotelId: number;
    bookingId: number;
    cancelData: CancelData;
  }) {
    // const data = await cancelBooking({ hotelId, bookingId, cancel_data })
    this.setBookingCancellationPennding(true);
    await cancelBooking({ hotelId, bookingId, cancelData });
    this.setBookingCancellationPennding(false);
  }

  get accompanyingGuests() {
    return this.bookingInfo?.accompanying_guests;
  }

  get totalGuestCount() {
    if (!this.bookingInfo) {
      return null;
    }
    const { childs, adults } = this.bookingInfo;
    const childsCount = childs.reduce((acc, item) => acc + item.quantity, 0);
    return adults + childsCount;
  }

  kinship: { list: Kinship[] | null; loading: boolean } = { list: null, loading: false };
  setKinship(data: Partial<typeof this.kinship>) {
    this.kinship = { ...this.kinship, ...data };
  }

  async loadKinshipTypes() {
    if (this.kinship.loading) {
      return;
    }
    this.setKinship({ loading: true });
    const data = await getKinshipTypes();
    this.setKinship({ list: data, loading: false });
    return data;
  }

  updateGuestPending = false;

  setUpdateGuestPending(state: boolean) {
    this.updateGuestPending = state;
  }

  createOrUpdateGuest = async (payload: ExtraGuest) => {
    const { firstName, lastName, middleName, kinship, guestId } = payload;
    const hotelId = this.root.configStore.hotelId;

    if (!hotelId) {
      return;
    }

    // yield put(creatingGuestPending(true));

    // if (files.length) {
    //   // new files were selected -> delete old attachments
    //   const userGuest: { attachments: Attachment[] } = yield select(
    //     getUserGuest,
    //     guestId
    //   );
    //   const [oldAttachment] = userGuest.attachments;

    //   if (guestId && oldAttachment) {
    //     // delete old file
    //     const deattachFileBodyReq = {
    //       guest_id: guestId,
    //       attachment_id: oldAttachment.id,
    //     };
    //     yield call(deattachGuestFile, userId, deattachFileBodyReq);
    //   }
    // }

    // const { id } = !files.length
    //   ? { id: null }
    //   : yield call(uploadFile, { userId, files });

    const extraGuestBodyRequest = {
      id: guestId,
      email: null,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      relation_degree_id: kinship,
      attachments: [],
      // attachments: id ? [id] : [],
    };

    // TODO show UI loaiding
    this.setUpdateGuestPending(true);

    const result = await createOrUpdateGuest(hotelId, extraGuestBodyRequest);

    await this.root.authStore.loadUserInfo();
    this.setUpdateGuestPending(false);

    const createdGuest = this.root.authStore.guestList.find(guest => guest.id === result?.id);
    return createdGuest;
  };

  deleteGuest = async (guestId: number) => {
    const hotelId = this.root.configStore.hotelId;
    if (!hotelId) {
      return; // TODO
    }
    this.setUpdateGuestPending(true);

    await deleteGuest(hotelId, guestId);
    await this.root.authStore.loadUserInfo();
    this.setUpdateGuestPending(false);
  };

  guestListUpdatePending = false;
  setGuestListUpdatePending(state: boolean) {
    this.guestListUpdatePending = state
  }

  async saveGuestList(bookingId: string, guestIds: number[]) {
    this.setGuestListUpdatePending(true)
    await updateBookingGuestList(bookingId, guestIds);
    this.setGuestListUpdatePending(false)
  }
}
