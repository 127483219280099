import { useEffect, useState } from "react";

const BREAKPOINT = 1024; // lg

export const useMonthsNumber = () => {
    const [numberOfMonths, toggleMontsShown] = useState(1);

    useEffect(() => {
        const resizeHandler = () => {
            const width = window.innerWidth;
            if (width > BREAKPOINT && numberOfMonths == 1) {
                toggleMontsShown(2);
            }
            if (width < BREAKPOINT && numberOfMonths == 2) {
                toggleMontsShown(1)
            }
        }
        window.addEventListener("resize", resizeHandler)

        resizeHandler()

        return () => window.removeEventListener("resize", resizeHandler)
    }, [numberOfMonths])
    return numberOfMonths;
}
