import './App.css';
import './assets/fonts/fonts.css';

import { Header } from './components/Header';
import { Outlet, ScrollRestoration, useNavigate, useSearchParams } from 'react-router-dom';
import { RootStoreContext } from './stores/storeContext';
import { rootStore } from './stores/rootStore';
import { useEffect } from 'react';
import { SBER_PASS_KEY } from './apiMethods/getSberToken';
import { ErrorModal } from './components/ErrorModal';
import SberLogoDefinitions from './assets/sber-logo-defs.svg?react';
import { ConfirmModal } from './components/ConfirmModal';

function useSberLogin() {
  const [searchParams] = useSearchParams();
  const oneTimePass = searchParams.get(SBER_PASS_KEY);

  const { authStore } = rootStore;

  const navigate = useNavigate();

  useEffect(() => {
    oneTimePass && authStore.loginWithSber(oneTimePass);
  }, [authStore, oneTimePass, navigate]);
}

function App() {
  useSberLogin();

  return (
    // <React.StrictMode>
    <RootStoreContext.Provider value={rootStore}>
      <Header />
      <main className="">
        <Outlet />
      </main>

      <ErrorModal />
      <ConfirmModal />
      <SberLogoDefinitions className="h-0" />
      <ScrollRestoration
        getKey={(location) => {
          const paths = ['/rooms'];
          return paths.includes(location.pathname) ? location.pathname : location.key;
        }}
      />
    </RootStoreContext.Provider>
    // </React.StrictMode>
  );
}

export default App;
