import { ReactNode } from "react"

export const GuestsLegend = () => {
    return (
        <div className="p-5 xl:flex-1 xl:px-8 xl:py-6">
            <LegendItem>Делюкс - номер для 3 взрослых и 1 ребенка до 3 лет.</LegendItem>
            <LegendItem>Семейный люкс - номер для 4 взрослых и 1 ребёнка до 3 лет.</LegendItem>
            <LegendItem>Взрослые  - гости от 14 лет и старше. Возраст на момент проживания</LegendItem>
        </div>
    )
}

const LegendItem = ({ children }: { children: ReactNode }) => (
    <p className="text-[12px] leading-4 xl:text-base font-light text-left pb-2">{children}</p>
)
