import { ReactNode } from 'react';

export const Skeleton = ({
  children,
  loading,
  className,
}: {
  children?: ReactNode;
  loading?: boolean;
  className?: string;
}) => {
  if (loading) {
    return <div className={`animate-pulse bg-neutral-40 ${className}`}></div>;
  }
  return children;
};
