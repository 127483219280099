import { Button } from './shared/Button/Button';
import { Dialog } from './shared/Dialog/Dialog';
import { HeadingUI2 } from './shared/Heading';
import CloseIcon from '../assets/close-icon.svg?react';
import { ReactNode } from 'react';

export const WarningModal = ({
  isOpen,
  onClose,
  title,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: ReactNode;
  children: ReactNode;
}) => {
  return (
    <>
      <Dialog isOpen={isOpen} onClose={onClose} className="!h-min">
        <div className="fixed bottom-0 w-full bg-neutral-0 xl:static">
          <div className="p-6 pb-8 pl-8 xl:w-[571px]">
            <div>
              <HeadingUI2 className="mb-5 pr-[52px] font-medium">{title}</HeadingUI2>
              {children}
            </div>
            <Button
              style="primary"
              variant="link"
              onClick={onClose}
              className="absolute right-5 top-2 !p-[14px] xl:right-6 xl:top-6"
            >
              <CloseIcon className={``} />
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
