import { RadioInput } from '../shared/RadioInput';
import { useRootStore } from '../../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { RateMode as RateModeType } from '../../stores/paramsStore';

type Props = {
  label: string;
  description: string;
  onChange: (value: string) => void;
  defaultChecked?: boolean;
  value: string;
};

const RateMode = ({ label, description, onChange, value, defaultChecked }: Props) => {
  return (
    <label className="flex flex-1 cursor-pointer items-start leading-6 text-neutral-900 xl:px-8 xl:py-5">
      <RadioInput
        name="rateMode"
        className="xl:m-1.5"
        onChange={() => onChange(value)}
        defaultChecked={defaultChecked}
      />
      <div className="pl-2">
        <p className="mb-2 text-sm font-medium xl:text-xl xl:leading-5 ">{label}</p>
        <span className="text-xs font-light text-neutral-70 xl:text-[16px] xl:leading-6 xl:text-neutral-700">
          {description}
        </span>
      </div>
    </label>
  );
};

export const rateModes: { [P in RateModeType]?: string } = {
  CORP: 'Корпоративный',
  SPEC: 'Специальный',
};

export const RateModes = observer(() => {
  const { paramsStore } = useRootStore();
  const onClick = (rateMode: string) => {
    paramsStore.setRateMode(rateMode as RateModeType);
  };

  return (
    <>
      <RateMode
        label={rateModes.CORP || ''}
        description="Тариф для работников и членов их семей. Необходимо подтверждение статуса сотрудника."
        onChange={onClick}
        value={'CORP'}
        defaultChecked={paramsStore.rateMode === 'CORP'}
      />
      <RateMode
        label={rateModes.SPEC || ''}
        description="Тариф с выгодой 20% без ограничений и подтверждения статуса сотрудника."
        onChange={onClick}
        value={'SPEC'}
        defaultChecked={paramsStore.rateMode === 'SPEC'}
      />
    </>
  );
});
