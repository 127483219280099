import { InputHTMLAttributes, Ref, TextareaHTMLAttributes, forwardRef } from 'react';
import './input-field.css';

export type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  label: string;
  innerRef?: Ref<HTMLInputElement>;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ onChange, onBlur, type, name, label, placeholder, error, autoComplete, className }, ref) => {
    return (
      <fieldset className={`custom-fieldset ${error && 'error'} ${className}`}>
        {error && <Error className="mt-2">{error}</Error>}

        <input
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          className="w-full rounded-none border-b border-b-neutral-40 pb-2 pt-[22px] text-[16px] focus:border-b-neutral-900 focus:outline-neutral-0/0"
        />

        <Label htmlFor={name}>{label}</Label>
      </fieldset>
    );
  },
);

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  error?: string;
};

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ onChange, onBlur, name, placeholder, label, error }, ref) => {
    return (
      <fieldset className={`custom-fieldset ${error && 'error'}`}>
        {error && <Error className="mt-2">{error}</Error>}

        <textarea
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          id={name}
          placeholder={placeholder}
          className="w-full border-b border-b-neutral-40 pb-2 pt-[22px] text-[16px] focus:border-b-neutral-900 focus:outline-neutral-0/0"
        ></textarea>

        <Label htmlFor={name}>{label}</Label>
      </fieldset>
    );
  },
);

const Label = ({ htmlFor, children }: { htmlFor?: string; children: string }) => (
  <label htmlFor={htmlFor} className="text-[16px] font-light uppercase text-neutral-900">
    {children}
  </label>
);

const Error = ({ children, className }: { children: string; className: string }) => (
  <span className={'text-sm text-critical-300' + ` ${className}`}>{children}</span>
);
