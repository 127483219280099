import {
  FloatingArrow,
  UseFloatingOptions,
  arrow,
  autoUpdate,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import { ReactNode, useRef } from 'react';

const defaultMiddleware = [shift(), offset(8)];

export const Tooltip = ({
  isOpen,
  anchor,
  children,
  middleware = defaultMiddleware,
}: {
  isOpen: boolean;
  anchor: ReactNode;
  children: ReactNode;
  middleware?: UseFloatingOptions['middleware'];
}) => {
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    placement: 'top',
    middleware: [...middleware, arrow({ element: arrowRef })],
  });

  return (
    <>
      <div ref={refs.setReference}>{anchor}</div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="rounded-md bg-neutral-700 font-light px-3 py-1 text-neutral-10 xl:max-w-[320px]"
        >
          <FloatingArrow ref={arrowRef} context={context} className='fill-neutral-700'></FloatingArrow>
          {children}
        </div>
      )}
    </>
  );
};
