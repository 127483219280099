// function* updateBookingGuestListWatcher(action: {
//     type: string;
//     payload: { bookingId: string; guestIds: number[] };
//   }) {
//     yield put(updateBookingGuestListPending(true));
//     const { bookingId, guestIds } = action.payload;

import { api } from "../utils/api";

  
//     yield call(submitNewBookingGuestList, bookingId, guestIds);
//     yield all([call(fetchUserInfo), call(getBookingDetails, { bookingId })]);
//     yield put(updateBookingGuestListPending(false));
//   }
  

export const updateBookingGuestList = async (
    bookingId: string,
    guestIds: number[]
  ) => {
    const response = await api.post(
      `/api/v1/reservation/edit/guests/${bookingId}/`,
      guestIds
    );
    return response;
  };
  