import { Outlet, useNavigate } from 'react-router-dom';
import { Room } from '../Room/Room';
import { DesktopFilters, MobileFilters } from '../components/RoomFilters/RoomFilters';
import { useRootStore } from '../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { Skeleton } from '../components/Skeleton';
import { Button } from '../components/shared/Button/Button';
import ArrowWithTailIcon from '../assets/arrow-tail.svg?react';
import { NotFoundPlug } from '../components/Plug/NotFoundPlug';
import { useRedirectToHomePage } from '../useRedirectToHomePage';

const RoomsNotfoundPlug = () => {
  const navigate = useNavigate();

  const title = () => (
    <span>
      не найдено номеров, <br /> подходящих под ваш запрос
    </span>
  );
  return (
    <NotFoundPlug title={title()} text="Пожалуйста, измените параметры запроса">
      <Button onClick={() => navigate('/')} className="mx-auto flex items-center gap-2 px-4 py-3 ">
        <ArrowWithTailIcon /> Вернуться к параметрам
      </Button>
    </NotFoundPlug>
  );
};

const LoadingRoom = () => (
  <Room
    loading={true}
    room={{
      id: 0,
      name: '',
      images: [],
      guest_limit: 0,
      for_disabled_people: true,
      min_price: 0,
      square: 0,
      nights: 0,
      sleeping_places: 0,
      short_description: '',
      view_type: '',
    }}
  />
);

export const RoomList = observer(() => {
  const { paramsStore } = useRootStore();

  useRedirectToHomePage();

  return (
    <>
      <div className="sticky top-0 z-10 hidden border-neutral-30 bg-neutral-0 xl:block xl:border">
        <div className="container mx-auto">
          <DesktopFilters />
        </div>
      </div>

      <div className="xl:hiddden">
        <MobileFilters />
      </div>

      <main className="container mx-auto max-w-[1168px] ">
        <section className="flex space-x-4 overflow-auto px-5 pb-8 xl:space-x-7 xl:px-0 xl:pb-5 3xl:absolute 3xl:left-[78px] 3xl:flex-col 3xl:space-x-0 3xl:space-y-4">
          {paramsStore.roomsPending && (
            <>
              <Skeleton loading className="h-7 w-[80px]"></Skeleton>
              <Skeleton loading className="h-7 w-[80px]"></Skeleton>
            </>
          )}
          {!paramsStore.roomsPending &&
            paramsStore.roomCategories.map((category) => (
              <Category
                key={category}
                selected={paramsStore.category === category}
                onClick={(c) => paramsStore.selectCategory(c)}
              >
                {category}
              </Category>
            ))}
        </section>

        {paramsStore.roomsPending && [1, 2, 3].map((x) => <LoadingRoom key={x} />)}
        {!paramsStore.roomsPending &&
          paramsStore
            .getRoomsByCategory()
            .map((r) => <Room key={r.id} room={{ ...r, nights: paramsStore.nightsCount }} />)}

        {!paramsStore.roomsPending && !paramsStore.getRoomsByCategory().length && (
          <RoomsNotfoundPlug />
        )}

        <Outlet />
      </main>
    </>
  );
});

const Category = ({
  children,
  selected,
  onClick,
}: {
  children: string;
  selected?: boolean;
  onClick: (category: string) => void;
}) => (
  <span className="flex cursor-pointer items-center" onClick={() => onClick(children)}>
    {selected && <div className="mr-1.5 h-2 w-2 rotate-45 bg-neutral-700 xl:mr-6"></div>}
    <span
      className={`whitespace-nowrap text-lg text-neutral-70 transition-colors ${selected && 'text-neutral-700'}`}
    >
      {children}
    </span>
  </span>
);
