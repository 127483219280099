import { useCallback, useEffect, useRef, useState } from 'react';

export const useAnimatedDialogHandler = (onCloseDelay = 300) => {
  const [isOpen, toggle] = useState(false);

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const openDialog = () => {
    (async function () {
      dialogRef.current?.showModal();
    })();
    toggle(true);
  };

  const closeDialog = useCallback(() => {
    toggle(false);
    setTimeout(() => dialogRef.current?.close(), onCloseDelay);
  }, [onCloseDelay]);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (event.target == element) {
        closeDialog();
      }
    };

    const element = dialogRef.current;
    element?.addEventListener('click', clickHandler);

    return () => element?.removeEventListener('click', clickHandler);
  }, [closeDialog]);

  return { ref: dialogRef, isOpen, openDialog, closeDialog };
};
