import mriyaLogo from '../assets/logo-mriya.svg';
import mriyaLogoMobile from '../assets/logo-mriya-mobile.svg';
import MenuIcon from '../assets/menu-icon.svg?react';
import CloseIcon from '../assets/close-icon.svg?react';
import UserIcon from '../assets/person-icon.svg?react';

import SberIcon from '../assets/sber-logo.svg?react';
import ChevronUpIcon from '../assets/chevron-up-icon.svg?react';
import './header.css';
import { Button } from './shared/Button/Button';
import { Dropdown } from './shared/Dropdown/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { CookieUtils } from '../utils/cookies';
import { Menu } from './Menu/Menu';
import { useAnimatedDialogHandler } from './shared/Dialog/useAnimatedDialogHandler';
import { useRootStore } from '../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { UserInfo } from '../apiMethods/getUserInfo';

const Logo = ({ className }: { className: string }) => (
  <Link to="/">
    <img src={mriyaLogoMobile} alt="mriya logo" className={`${className} my-3 lg:hidden`} />
    <img src={mriyaLogo} alt="mriya logo" className={`${className} hidden lg:block`} />
  </Link>
);

const User = ({ isOpen, name }: { isOpen: boolean; name: string }) => {
  const { authStore } = useRootStore();
  return (
    <div className="hidden cursor-pointer lg:flex">
      {authStore.isCompanyEmployee && <SberIcon className="mr-3" />}
      <span>{name}</span>
      <ChevronUpIcon
        className={`ml-1 w-6 rotate-180 transition-transform ${isOpen && 'rotate-0'}`}
      />
    </div>
  );
};

export const Header = observer(() => {
  const { authStore, configStore } = useRootStore();
  const phone = configStore.config?.phone1;

  const { ref, isOpen, openDialog, closeDialog } = useAnimatedDialogHandler(300);

  const navigate = useNavigate();

  const logout = () => {
    CookieUtils.clearToken();
    navigate('/');
    authStore.setUser(undefined);
  };

  return (
    <header className="flex items-center justify-between px-2 lg:p-5 xl:px-6">
      <div className="flex items-center justify-center lg:order-last">
        <a className="mr-8 hidden lg:block" href={`tel:${phone}`}>
          <span>{phone}</span>
        </a>

        <div className="hidden lg:flex">
          {authStore.user ? (
            <Dropdown
              placement="bottom-end"
              anchor={(isOpen) => <User isOpen={isOpen} name={authStore.user?.first_name || ''} />}
              mode="hover"
            >
              <div className="flex flex-col space-y-4 bg-neutral-0 p-6 shadow-xl">
                <Link to="/account">
                  <Button style="primary" variant="link">
                    Личный кабинет
                  </Button>
                </Link>
                <Button style="primary" variant="link" onClick={logout}>
                  Выход
                </Button>
              </div>
            </Dropdown>
          ) : (
            <Link to={'/welcome'}>
              <Button style="primary" variant="link">
                Войти
              </Button>
            </Link>
          )}
        </div>

        <Button
          style="primary"
          variant="link"
          onClick={openDialog}
          className="relative !p-3 xl:ml-8"
        >
          <CloseIcon className={`custom-menu-icon  ${isOpen && 'open'} absolute left-3 top-3`} />
          <MenuIcon className={`custom-menu-icon  ${!isOpen && 'open'} `} />
        </Button>
      </div>

      <Logo className="cursor-pointer" />
      <Menu ref={ref} isOpen={isOpen} onClose={closeDialog} />

      <MobileLogin user={authStore.user} />
    </header>
  );
});

export const BasicAvatar = ({ user }: { user: UserInfo }) => {
  const userInitials = `${user?.first_name[0]}${user?.last_name[0]}`;
  return (
    <div className="p-1.5">
      <div className="flex h-9 w-9 items-center justify-center rounded-full bg-primary-100 p-1.5 font-title text-sm font-light text-neutral-0 ">
        {userInitials}
      </div>
    </div>
  );
};

const SberEmployeeAvatar = ({ user }: { user: UserInfo }) => (
  <div className="flex w-[80px] flex-col items-center">
    <SberIcon className="mb-0.5" />
    <span className="text-[8px] font-light leading-3 text-neutral-900">{user.first_name}</span>
  </div>
);

const MobileLogin = ({ user }: { user?: UserInfo }) => {
  if (user?.is_company_employee) {
    return (
      <Link to={'/account'} className="lg:hidden">
        <SberEmployeeAvatar user={user} />
      </Link>
    );
  }

  if (user) {
    return (
      <Link to={'/account'} className="lg:hidden">
        <BasicAvatar user={user} />
      </Link>
    );
  }

  return (
    <Link to={'/welcome'} className="lg:hidden">
      <Button variant="link" className="!p-3">
        <UserIcon />
      </Button>
    </Link>
  );
};
