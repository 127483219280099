import { api } from '../utils/api';

export type CancelData = {
  cancel_type_id: number;
  cancel_comment?: string;
};

export async function cancelBooking({
  hotelId,
  bookingId,
  cancelData,
}: {
  hotelId: number;
  bookingId: number;
  cancelData: CancelData;
}) {
  const bodyRequest = {
    reservation_id: bookingId,
    status: 'CANCELED_BY_GUEST',
    cancel_data: cancelData,
  };

  const url = `/api/v1/reservation-status/${hotelId}/`;
  await api.post(url, bodyRequest);
}
