import { useEffect, useRef } from 'react';

export const useDialogHandler = (isOpen: boolean, onClose: () => void) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      (async function () {
        ref.current?.showModal();
      })();
    } else {
      setTimeout(() => ref.current?.close(), 0);
    }
  }, [isOpen]);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (event.target == ref.current) {
        onClose();
      }
    };

    const element = ref.current;
    element?.addEventListener('click', clickHandler);

    return () => element?.removeEventListener('click', clickHandler);
  }, [onClose]);

  return { ref };
};
