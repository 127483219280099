import { ReactNode, useEffect, useState } from 'react';

export const CenteredLayout = ({ children }: { children: ReactNode }) => {
  const [hh, setHH] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const header = document.querySelector('header');
      header && setHH(header.offsetHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <main
      className="container absolute bottom-0 left-0 right-0 mx-auto flex max-w-[870px] items-center justify-center p-5"
      style={{ top: hh }}
    >
      <div className="w-full max-w-[375px]">{children}</div>
    </main>
  );
};
