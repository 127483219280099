import { useContext } from "react";
import { RootStoreContext } from "./storeContext";

export function useRootStore() {
    const context = useContext(RootStoreContext);
    if (!context) {
        throw new Error('no StoreContext was found')
    }

    return context;
}