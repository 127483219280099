import { useNavigate } from 'react-router-dom';
import { Button } from '../components/shared/Button/Button';
import { Calendar } from '../components/Calendar/Calendar';
import { Guests } from '../components/Guests';
import { RateModes } from '../components/RateModes/RateModes';
import { Promocode } from '../components/Promocode/Promocode';
import { StickyBottom } from '../components/shared/StickyBottom/StickyBottom';
import { Heading1, Heading3 } from '../components/shared/Heading';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../stores/useRootStore';

export const ParamsSetup = observer(() => {
  const navigate = useNavigate();

  const { configStore, authStore, paramsStore } = useRootStore();

  const searchRooms = async () => {
    navigate('/rooms');
    await paramsStore.loadRooms();
  };

  return (
    <main className=" container mx-auto max-w-[1132px]">
      <Heading1 className="mt-8 hidden text-center xl:block">Забронировать отдых</Heading1>

      {authStore.isCompanyEmployee && (
        <section className="border border-neutral-30 px-5 py-8 xl:mb-10 ">
          <Heading3 className="text-center">Тариф</Heading3>
          <div className="space-y-4 xl:space-y-0 xl:flex">
            <RateModes />
          </div>
        </section>
      )}

      <section className="container mx-auto mt-6 flex justify-center">
        <Calendar />
      </section>

      <section className="mb-10 mt-10 flex flex-col justify-center xl:flex-row">
        <Guests />
      </section>

      {configStore.hasPromocode && (
        <section className="mb-10 border border-neutral-30 px-5 py-8">
          <Promocode />
        </section>
      )}

      <StickyBottom>
        <Button
          className="w-full xl:w-auto"
          onClick={searchRooms}
          disabled={!paramsStore.range?.from || !paramsStore.range?.to}
        >
          Подобрать номера
        </Button>
      </StickyBottom>
    </main>
  );
});
