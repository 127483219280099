import { api } from '../utils/api';
import { RateSummary } from '../views/Account/BookingList';

export type BookingItem = {
  id: number;
  crs_number: string;
  room_type: string;
  created: string;
  //   created: '2024-07-31T12:39:33.532315+03:00';
  arrival: string;
  //   arrival: '2024-07-31';
  departure: string;
  //   departure: '2024-08-01';
  is_sber_employee: boolean;
  status: string;
  external_id: null;
  rate: RateSummary;
  confirmed: boolean;
  price: string;
  adults: number;
  get_guest_childs: number;
  room_type_props: {
    id: number;
    name: string;
    code: string;
    img: string;
    short_description: string;
    long_description: string;
    square: number;
  };
  //   price: '60.00';
};

export async function getBookingList(hotelId: number) {
  const url = `/api/v1/account-reservation/list/${hotelId}/`;

  const response = await api.get<BookingItem[]>(url);
  return response;
}
