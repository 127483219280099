import { InputHTMLAttributes, forwardRef } from "react";
import './custom-checkbox.css';

export const Checkbox = forwardRef<HTMLInputElement, Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>>((props, ref) => (
    <span className="custom-checkbox" >
        <input type="checkbox" ref={ref} {...props} />

        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.23529L5 10L12 1" stroke="white" />
        </svg>
    </span>
))
