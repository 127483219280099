import { api } from '../utils/api';

type Params = {
  arrival: string;
  departure: string;
  promo_code?: string;
  rate_flow: string;
  rooms: {
    adults: number;
    childs: { id: number; count: number }[];
  }[];
};

export type Rate = {
  id: number;
  name: string;
  rate_code: string;
  long_description: string;
  short_description: string;
  full_boarding: boolean;
  card_payment: boolean;
  total_price: number;
  original_total_price: null | number;
  nights: {
    rate_code: string;
    room_code: string;
    date: string;
    price: number;
    availability: number;
  }[];
};

export type Room = {
  id: number;
  room_code: string;
  category: string;
  block_id: null | number;
  name: string;
  short_description: string;
  long_description: string;
  preview_img: string;
  room_options: [];
  images: string[];
  rates: Rate[];
  original_price: number | null;
  min_price: number;
  max_price: number;
  priority: number;
  square: number;
  limit: null;
  guest_limit: number;
  is_valid: boolean;
  for_disabled_people: boolean;
  capacity: string | null;
  sleeping_places: number;
  view_type: null;
  last_room: boolean;
  sales_leader: boolean;
  room_services: null | string;
};

export async function getRooms(hotelId: number, bodyRequest: Params) {
  const url = `/api/v1/ota-availability/${hotelId}/`;

  const response = await api.post<Room[]>(url, bodyRequest);
  return response;
}
