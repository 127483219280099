import { makeAutoObservable } from 'mobx';
import { RootStore } from './rootStore';

type Confirmation = {
  text: string;
  title?: string;
  okLabel?: string;
  cancelLabel?: string;
  proceed: (value: unknown) => void;
  cancel: () => void;
};

export class ConfirmStore {
  root: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.root = rootStore;
  }

  confirmation: null | Confirmation = null;
  setConfirmation(confirmation: null | Confirmation) {
    this.confirmation = confirmation;
  }

  clearConfirmation() {
    this.confirmation = null;
  }

  isConfirmed: null | boolean = null;
  setIsConfirm(value: boolean) {
    this.isConfirmed = value;
  }

  confirm = async ({ title, text }: { title?: string; text: string }) => {
    const promise = new Promise((resolve, reject) => {
      this.setConfirmation({ title, text, proceed: resolve, cancel: reject });
    });
    return promise.then(
      () => {
        this.clearConfirmation();
        return true;
      },
      () => {
        this.clearConfirmation();
        return false;
      },
    );
  };
}
