import { WarningModal } from './WarningModal';
import { useRootStore } from '../stores/useRootStore';
import { Button } from './shared/Button/Button';
import { observer } from 'mobx-react-lite';

export const ConfirmModal = observer(() => {
  const { confirmStore } = useRootStore();

  const closeModal = () => {
    confirmStore.confirmation?.cancel();
  };

  const { confirmation } = confirmStore;

  if (!confirmation) {
    return null;
  }

  return (
    <WarningModal
      title={confirmation.title || 'Внимание'}
      isOpen={Boolean(confirmation)}
      onClose={closeModal}
    >
      {confirmation.text}

      <div className="mt-5 flex gap-2">
        <Button onClick={() => confirmation.cancel()}>
          {confirmation.cancelLabel || 'Отмена'}
        </Button>
        <Button style="secondary" onClick={() => confirmation.proceed(true)}>
          {confirmation.okLabel || 'Продолжить'}
        </Button>
      </div>
    </WarningModal>
  );
});
