import { ReactNode, useEffect, useRef, useState } from 'react';

import './details.css';
import ChevronUpIcon from '../../../assets/chevron-up-icon.svg?react';

export const Details = ({
  label,
  children,
  className,
}: {
  label: string | ((isOpen?: boolean) => ReactNode);
  children: ReactNode;
  className?: string;
}) => {
  const ref = useRef<HTMLDetailsElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    element.addEventListener('toggle', () => {
      setIsOpen(ref.current?.open || false);
    });
  }, []);

  return (
    <details ref={ref} className={`custom-details ${className}`}>
      {typeof label === 'string' ? (
        <summary className="mb-1">
          <span className="flex cursor-pointer font-light text-primary-300">
            {label}

            <ChevronUpIcon className="custom-icon ml-2 rotate-180" />
          </span>
        </summary>
      ) : (
        <summary>{label(isOpen)}</summary>
      )}
      <div className="custom-details-content space-y-4 ">{children}</div>
    </details>
  );
};
