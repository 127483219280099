import { ReactNode } from "react";
import { Skeleton } from "../../../components/Skeleton";

export const TableRow = ({
  label,
  children,
  loading,
  skeletonClass
}: {
  label: string;
  children: ReactNode;
  loading?: boolean;
  skeletonClass: [string, string]
}) => (
  <tr>
    <td className="mr-2 min-w-[116px] content-start text-neutral-70">
      <Skeleton loading={loading} className={`h-[20px] w-[80px] ${skeletonClass[0]}`}>
        {label}
      </Skeleton>
    </td>
    <td>
      <Skeleton loading={loading} className={`h-[20px] w-[150px] ${skeletonClass[1]}`}>
        {children}
      </Skeleton>
    </td>
  </tr>
);