import SberIcon from '../../assets/sber-logo-small.svg?react';
import { config } from '../../config';
import { CenteredLayout } from '../../components/shared/CenteredLayout';
import { Link } from 'react-router-dom';
import { Button } from '../../components/shared/Button/Button';
import { Heading1 } from '../../components/shared/Heading';

export const AuthWelcome = () => {

  return (
    <CenteredLayout>
      <div className="mb-10 text-center">
        <Heading1>Вход</Heading1>
        <p className="text-lg font-light tracking-tight">
          Уважаемые Гости! Вход в модуль онлайн-бронирования осуществляется по Сбер ID.
        </p>
      </div>

      <div className="space-y-4">
        <div id="sber"></div>

        <SberLoginButton />

        <Link to={'/auth'} className="block">
          <Button style="secondary" className="w-full !bg-neutral-700 !text-neutral-0">
            Войти через логин
          </Button>
        </Link>
      </div>
    </CenteredLayout>
  );
};

const SberLoginButton = () => {
  const { apiUrl, sberLoginAppUrl } = config;

  return (
    <a href={`${apiUrl}/api/v1/users/sberID-login-form?app_url=${sberLoginAppUrl}`}>
      <Button
        style="secondary"
        className="flex w-full items-center justify-center !bg-hz-1 !text-neutral-0"
      >
        <span className="mr-2">
          <SberIcon />
        </span>
        Войти по Сбер ID
      </Button>
    </a>
  );
};
