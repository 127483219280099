import { makeAutoObservable, reaction } from 'mobx';
import { RootStore } from './rootStore';
import { addMonths, format, isBefore, startOfDay } from 'date-fns';
import { Availability, getAvailability } from '../apiMethods/getAvailability';
import { CookieUtils } from '../utils/cookies';

export class ColorAvailabilityStore {
  root: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.root = rootStore;
  }

  init() {
    this.disposers = [
      reaction(
        () => this.availabilityParams,
        (params) => {
          params && this.loadAvailability(params);
        },
      ),
    ];
  }

  disposers: (() => void)[] = [];

  dispose() {
    console.info('disposed availabilityStore');
    this.disposers.forEach((f) => f());
  }

  availabilityStart = startOfDay(new Date());

  setAvailabilityStart(_date: Date) {
    const today = startOfDay(new Date());
    let date = startOfDay(_date);

    if (isBefore(date, today)) {
      date = today;
    }

    date.valueOf() !== this.availabilityStart.valueOf() && (this.availabilityStart = date);
  }

  get availabilityParams() {
    const token = CookieUtils.getToken();
    const user = this.root.authStore.user;
    if (token && !user) {
      return;
    }
    
    const { rateMode, promocode } = this.root.paramsStore;
    const hotelId = this.root.configStore.hotelId;
    const start = this.availabilityStart;
    return { hotelId, start, rateFlow: rateMode, withPromocode: Boolean(promocode) };
  }

  loadAvailability = async (params: {
    hotelId?: number;
    start?: Date;
    withPromocode: boolean;
    rateFlow?: string;
  }) => {
    const { hotelId, start, withPromocode, rateFlow } = params;
    if (!hotelId || !start) {
      return;
    }

    const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

    const arrival = formatDate(start);
    const departure = formatDate(addMonths(start, 3));
    const result = await getAvailability(hotelId, { arrival, departure, rateFlow, withPromocode });

    this.setAvailability(result || []);
  };

  availability: Availability[] = [];

  setAvailability(data: typeof this.availability) {
    this.availability = data;
  }
}
