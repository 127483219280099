import { ReactNode } from 'react';
import { useDialogHandler } from './useDialogHandler';

export const Dialog = ({
  children,
  onClose,
  isOpen,
  className,
}: {
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  className?: string;
}) => {
  const { ref } = useDialogHandler(isOpen, onClose);

  return (
    <dialog
      ref={ref}
      onCancel={onClose}
      className={`m-0 max-h-full h-full max-w-full backdrop:bg-neutral-800/60 xl:m-auto xl:h-[800px] xl:max-w-[1280px] ${className}`}
    >
      {children}
    </dialog>
  );
};
