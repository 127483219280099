import { makeAutoObservable } from 'mobx';
import { UserInfo, getUserInfo } from '../apiMethods/getUserInfo';
import { RootStore } from './rootStore';
import { getToken } from '../apiMethods/getToken';
import { CookieUtils } from '../utils/cookies';
import { getSberToken } from '../apiMethods/getSberToken';

export class AuthStore {
  root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this);
  }

  async init() {
    const token = CookieUtils.getToken();
    token && (await this.loadUserInfo());
  }

  async fetchToken({ login, password }: { login: string; password: string }) {
    const response = await getToken({ username: login, password: password });
      response?.token && CookieUtils.setToken(response.token);
      return response?.token;
  }

  async fetchSberToken(oneTimePass: string) {
    const { token } = await getSberToken(oneTimePass);
    token && CookieUtils.setToken(token);
    return token;
  }

  user?: UserInfo;

  setUser(userInfo?: UserInfo) {
    this.user = userInfo;
  }

  async loadUserInfo() {
    const user = await getUserInfo();

    user && this.setUser(user);
  }

  get isCompanyEmployee() {
    return this.user?.is_company_employee;
  }

  get guestList() {
    return this.user?.user_guests || [];
  }

  async login(credentials: { login: string; password: string }) {
    const token = await this.fetchToken(credentials);
    token && await this.loadUserInfo();
    if (token) {
      return true;
    }
  }

  async loginWithSber(oneTimePass: string) {
    const token = await this.fetchSberToken(oneTimePass);
    token && await this.loadUserInfo();
  }
}
