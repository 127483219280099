import { ReactNode } from 'react';
import { Heading1 } from '../shared/Heading';

export const NotFoundPlug = ({
  title,
  text,
  children,
}: {
  title: ReactNode;
  text: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <section className="px-5 text-center">
      <Heading1 className="">{title}</Heading1>
      <p className="mb-6">{text}</p>
      {children}
    </section>
  );
};
