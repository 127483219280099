import { parse } from "date-fns"
import { createContext } from "react"

type Availability = {
    "date": string,
    "availability"?: {
        "font_color"?: string,
        "color"?: string,
        "legend"?: string,
    },
    "price"?: number;
}

type Colors = { [P in string]: { color: string; legend: string } }

export const CalendarContext = createContext<{ colors: Colors; prices: { [P in string]: number } }>({ colors: {}, prices: {} });

export const useAvailability = (availability: Availability[]) => {
    const { modifiers, colors, prices } = availability.reduce((acc, curr) => {
        const key = curr.availability?.legend;
        const color = curr.availability?.color;
        const date = parse(curr.date, 'yyyy-MM-dd', new Date())
        const price = curr.price;

        const { modifiers, colors, prices } = acc;

        if (key) {
            // handle availability modifiers
            if (!modifiers[key]) {
                modifiers[key] = [date]
            } else {
                modifiers[key].push(date)
            }

            if (!colors[key]) {
                colors[key] = { color: color!, legend: key };
            }
        }

        if (price) {
            // handle price modifiers
            modifiers[curr.date] = [date]
            prices[curr.date] = price;
        }

        return acc;
    }, { modifiers: {}, colors: {}, prices: {} } as { modifiers: { [P in string]: Date[] }; colors: Colors; prices: { [P in string]: number } })

    return { modifiers, colors, prices }
}

