import {
  type Placement,
  type UseFloatingOptions,
  safePolygon,
  useFloating,
  useHover,
  useInteractions,
  shift,
  offset,
  FloatingPortal,
  useClick,
  useDismiss,
} from '@floating-ui/react';
import { ReactNode, useState } from 'react';

type DropdownProps = {
  children: ReactNode;
  anchor: (isOpen: boolean) => ReactNode;
  placement?: Placement;
  middleware?: UseFloatingOptions['middleware'];
  mode?: 'click' | 'hover';
};

const defaultMiddleware = [shift(), offset(8)];

export const Dropdown = ({
  children,
  anchor,
  placement = 'bottom',
  middleware = defaultMiddleware,
  mode = 'click',
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware,
  });

  const hover = useHover(context, { handleClose: safePolygon(), enabled: mode == 'hover' });
  const click = useClick(context, { enabled: mode == 'click' });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss]);

  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()}>
        {anchor(isOpen)}
      </span>

      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            className="absolute z-50"
            style={floatingStyles}
          >
            {children}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
