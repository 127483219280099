import CloseIcon from '../../assets/close-icon.svg?react';
import { forwardRef } from 'react';
import { Button } from '../shared/Button/Button';
import { Link, LinkProps } from 'react-router-dom';
import { IconButton } from '../shared/IconButton/IconButton';
import { useRootStore } from '../../stores/useRootStore';
import { CookieUtils } from '../../utils/cookies';

const MenuLink = ({ children, ...props }: LinkProps) => (
  <Link {...props} className="block text-[16px] leading-6 text-neutral-900">
    {children}
  </Link>
);

export const Menu = forwardRef<HTMLDialogElement, { isOpen: boolean; onClose: () => void }>(
  ({ isOpen, onClose }, ref) => {
    const { configStore, authStore } = useRootStore();

    const menuItems = configStore.config?.hotel_menu_items || [];

    const logout = () => {
      CookieUtils.clearToken();
      authStore.setUser(undefined);
      onClose();
    };

    const phone = configStore.config?.phone1;

    return (
      <dialog
        ref={ref}
        className={`m-0 h-full max-h-full w-full max-w-full bg-neutral-0/0 
          backdrop:bg-neutral-0/0 backdrop:transition-colors backdrop:duration-300
          ${isOpen && 'xl:backdrop:!bg-neutral-900/60'}`}
      >
        <div
          className={`fixed bottom-0 top-[56px] flex w-full -translate-x-full flex-col bg-neutral-0
            transition-transform duration-300 
            xl:right-0 xl:top-0 xl:max-w-[450px] xl:translate-x-full ${isOpen && '!translate-x-0'}`}
        >
          <div className="right-0 top-0 mr-6 mt-7 hidden text-right xl:block">
            <IconButton
              Icon={CloseIcon}
              variant="link"
              style="primary"
              onClick={onClose}
            ></IconButton>
          </div>

          <section className="border-y border-neutral-30 px-5 py-6 xl:hidden">
            {authStore.user ? (
              <>
                <Link to={'/account'} onClick={onClose}>
                  <Button variant="outlined" style="accent" className="w-full">
                    Личный кабинет
                  </Button>
                </Link>
              </>
            ) : (
              <Link to={'/welcome'} onClick={onClose}>
                <Button variant="filled" style="primary" className="w-full ">
                  Войти
                </Button>
              </Link>
            )}
            <a href={`tel:${phone}`}>
              <Button style="secondary"  className="w-full mt-2">
                {phone}
              </Button>
            </a>
          </section>

          <section className="flex-1 space-y-6 overflow-y-auto px-5 py-6">
            {menuItems.map((mi) => (
              <MenuLink target="_blank" key={mi.name} to={mi.reference}>
                {mi.name}
              </MenuLink>
            ))}
          </section>

          {authStore.user && (
            <section className="px-5 py-6 xl:hidden">
              <Button variant="outlined" style="primary" className="w-full" onClick={logout}>
                Выйти
              </Button>
            </section>
          )}
        </div>
      </dialog>
    );
  },
);
