import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import { useEffect, useRef } from 'react';

type GalleryProps = {
  galleryID: string;
  images: { largeURL: string; width: number; height: number; thumbnailURL: string }[];
};

export default function SimpleGallery(props: GalleryProps) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryID,
      children: 'a',
      pswpModule: PhotoSwipe,
      index: 0,
      appendToEl: dialogRef.current || undefined,
      hideAnimationDuration: 300,
    });

    lightbox.on('beforeOpen', async () => {
      (async function () {
        dialogRef.current?.showModal();
      })();
    });
    lightbox.on('closingAnimationEnd', () => {
      dialogRef.current?.close();
    });
    lightbox.init();

    return () => {
      lightbox?.destroy();
      lightbox = null;
    };
  }, [props.galleryID]);

  return (
    <>
      <div className="relative h-full overflow-hidden">
        <div className="pswp-gallery h-full" id={props.galleryID}>
          {props.images.map((image, index) => (
            <a
              href={image.largeURL}
              data-pswp-width={image.width}
              data-pswp-height={image.height}
              key={props.galleryID + '-' + index}
              target="_blank"
              rel="noreferrer"
            >
              <img src={image.thumbnailURL} alt="" className="h-full w-full object-cover" />
            </a>
          ))}
        </div>
      </div>
      <dialog ref={dialogRef} id="gallery-dialog-container"></dialog>
    </>
  );
}
